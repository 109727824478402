import { Accordion, ArchiveIcon, Avatar, Box, ChatIcon, CloseIcon, ComposeIcon, Divider, DownloadIcon, EditIcon, EyeIcon, EyeSlashIcon, FilterIcon, Flex, Input, List, Loader, Menu, MoreIcon, ParticipantAddIcon, PersonIcon, Pill, SearchIcon, Text, ThumbtackIcon, ThumbtackSlashIcon, TrashCanIcon, UserFriendsIcon, menuAsToolbarBehavior } from '@fluentui/react-northstar'
import { ConversationModel, Props, UserModel } from 'Models'
import { ConversationService } from 'Services'
import { filter, findIndex, map } from 'lodash'
import moment from 'moment'
import { FC, SyntheticEvent, useContext, useEffect, useRef, useState } from 'react'
import { AddContact } from './AddContact'
import { AssignUser } from './AssignUser'
import { ConformationDialog } from './ConformationDialog'
import { TeamsFxContext } from './Context'
import { EditContact } from './EditContact'

const ContactList: FC<Props & {
    users: Array<ConversationModel>, onSearch: any,
    onItemSelected: (item: ConversationModel) => void,
    selected: ConversationModel | null,
    onDeleteConversation: (item: ConversationModel) => void
    onEditConversation: (item: ConversationModel, edit: boolean) => void
    onFilterClick: (filter: 'UNREAD' | 'UNANSWERED' | 'ACTIVE' | 'ALL') => void, filter: 'UNREAD' | 'UNANSWERED' | 'ACTIVE' | 'ALL',
    loadMore: () => void,
    loading: boolean
    type: 'MY_CONVERSATIONS' | 'INBOX',
    onTypeSelected: (type: 'MY_CONVERSATIONS' | 'INBOX') => void
}> = (props) => {

    const { users, onSearch, onItemSelected, selected, loading, t, type, onTypeSelected } = props;
    const { auth } = useContext(TeamsFxContext);
    const scrollRef = useRef<HTMLDListElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const refState = useRef<{ actionTriggered: boolean }>({ actionTriggered: false });
    const [state, setState] = useState<{
        searchable: boolean,
        new: {
            open: boolean
        },
        edit: {
            open: boolean,
            item: ConversationModel
        },
        assign: {
            open: boolean,
            item: ConversationModel | null
        }
        confirm: {
            open: boolean,
            item: ConversationModel,
            title: string,
            subTitle: string
        },
        pinnedList: Array<string>
    }>({
        searchable: false,
        new: {
            open: false
        },
        edit: {
            open: false,
            item: new ConversationModel()
        },
        assign: {
            open: false,
            item: null
        },
        confirm: {
            open: false,
            item: new ConversationModel(),
            title: '',
            subTitle: ''
        },
        pinnedList: []
    })
    const handleChange = (e: SyntheticEvent<HTMLElement>, { value }: any) => {
        if (onSearch) {
            onSearch(value)
        }
    }

    const handleItemSelected = (item: ConversationModel) => {
        if (onItemSelected) {
            onItemSelected(item)
        }
    }

    const getStatus = (item: ConversationModel): "unknown" | "success" | "info" | "warning" | "error" => {
        if (item.timestamp && moment(item?.timestamp * 1000).isAfter(moment().subtract(24, 'hours'))) {
            return "success";
        }
        if (item.timestamp && moment(item?.timestamp * 1000).isAfter(moment().subtract(48, 'hours'))) {
            return "warning";
        }
        return "unknown";
    }

    const onAssignModalOpen = (item: ConversationModel) => {
        setState(prevState => {
            prevState.assign.item = item;
            prevState.assign.open = true;
            return { ...prevState }
        });
    }

    const onAssignModalClose = () => {
        setState(prevState => {
            prevState.assign.item = null;
            prevState.assign.open = false;
            return { ...prevState }
        });
    }

    const onNewModalOpen = () => {
        setState(prevState => {
            prevState.new.open = true;
            return { ...prevState }
        });
    }

    const onNewModalClose = () => {
        setState(prevState => {
            prevState.new.open = false;
            return { ...prevState }
        });
    }

    const onEditModalClose = () => {
        setState(prevState => {
            prevState.edit.open = false;
            prevState.edit.item = new ConversationModel();
            return { ...prevState }
        });
    }

    const onSearchViewChange = (bool: boolean) => {
        setState(prevState => {
            prevState.searchable = bool;
            return { ...prevState }
        });
        onSearch('')
    }

    useEffect(() => {
        inputRef.current?.focus()
    }, [state.searchable])

    useEffect(() => {
        scrollRef.current?.addEventListener("scroll", () => {
            if (scrollRef.current) {
                const { scrollHeight, clientHeight, scrollTop } = scrollRef.current;
                const threshold = 90;
                const atBottom = scrollTop + clientHeight >= (threshold / 100) * scrollHeight;
                if (refState.current.actionTriggered) {
                    return;
                }
                if (atBottom) {
                    refState.current.actionTriggered = true;
                    props.loadMore()
                }
            }
            return;
        });
        const getPinnedConversations = () => {
            ConversationService.getPinnedConversations().then((resp) => {
                setState(prevState => {
                    prevState.pinnedList = map(resp?.data, item => item?.conversationId)
                    return { ...prevState }
                })
            })
        }
        getPinnedConversations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        refState.current.actionTriggered = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    const onArchiveConversation = (item: ConversationModel) => {
        const params = {
            status: item.status === 'ARCHIVED' ? 'ACTIVE' : 'ARCHIVED'
        }
        ConversationService.update(item.uuid, params).then((resp) => {
        })
    }

    const onDeleteConversation = (item: ConversationModel) => {
        setState(prevState => {
            prevState.confirm.item = item
            prevState.confirm.open = true
            prevState.confirm.title = props.t('DIALOG.DELETE_CONVERSATION.TITLE')
            prevState.confirm.subTitle = props.t('DIALOG.DELETE_CONVERSATION.DESCRIPTION')
            return { ...prevState }
        })
    }

    const onDownloadConversation = (item: ConversationModel) => {
        if (item.uuid) {
            ConversationService.download(item.uuid).then((resp) => {
                const url = window.URL.createObjectURL(resp)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `Gesprek__${item.client.firstName}_${item.client.lastName}_${moment(moment.now()).format('DD-MM-YYYY')}.pdf`)
                link.click()
            })
        }
    }

    const onEditUser = (item: ConversationModel) => {
        setState(prevState => {
            prevState.edit.item = item;
            prevState.edit.open = true
            return { ...prevState }
        })
    }

    const pinnedConversation = (item: ConversationModel, pinned: "TRUE" | "FALSE") => {
        if (pinned === "TRUE") {
            ConversationService.unPinnedConversation(item.uuid).then((resp) => {
                setState(prevState => {
                    prevState.pinnedList = prevState.pinnedList.filter(uuid => uuid !== item.uuid)
                    return { ...prevState }
                })
            }).catch(console.error)
        } else {
            ConversationService.pinnedConversation({ conversationId: item.uuid }).then((resp) => {
                setState(prevState => {
                    prevState.pinnedList = [...prevState.pinnedList, item.uuid]
                    return { ...prevState }
                })
            }).catch(console.error)
        }
    }

    const onEditConversation = (item: UserModel) => {
        if (state.edit.item) {
            state.edit.item.client = item
            // props.onEditConversation(state.edit.item, true)
        }

    }

    const onMarkAsUnread = (item: ConversationModel) => {
        ConversationService.markAsUnread(item.uuid, { count: 1 }).then((resp) => {
            const { data, status } = resp;
            if (status === 200) {
                item.count = data.count;
                //  props.onEditConversation(item, true)
            }
            // setState(prevState => {
            //     prevState.unreadList = prevState.unreadList.filter(uuid => uuid !== item.uuid)
            //     return { ...prevState }
            // })
        }).catch(console.error)
    }

    const renderItem = (item: ConversationModel) => {
        const pinned = state.pinnedList.includes(item.uuid)
        const users: Array<string> = map(item.users, (row) => row.uuid);
        return {
            className: 'user-item',
            key: item.uuid,
            pinned: pinned ? "TRUE" : "FALSE",
            media: (
                <Avatar status={{ state: getStatus(item) }} name={item?.client?.firstName} style={{ color: '#fff', backgroundColor: item?.client?.color, borderRadius: '50%' }} />
            ),
            header: `${item?.client?.firstName || ''} ${item?.client?.lastName || ''}`,
            headerMedia: item?.timestamp ? moment(item?.timestamp * 1000).calendar({
                lastWeek: `[${t('LABEL.LAST_WEEK')}] dddd`,
                lastDay: `[${t('LABEL.LAST_DAY')}] HH:mm`,
                sameDay: `[${t('LABEL.SAME_DAY')}] HH:mm`,
                sameElse: `DD/MM/YYYY`
            }) : null,
            truncateContent: true,
            truncateHeader: true,
            content: (<Flex gap="gap.small" styles={{ backgroundColor: 'inherit !important' }}>
                <Text weight='bold' content={item?.count >= 1 ? t('LABEL.UNREAD_MESSAGE') : '\u00A0'} />
                {!!item?.users?.length && <Flex.Item push>
                    <Text className='user-name' content={map(item?.users, user => `${user?.firstName || ''} ${user?.lastName || ''}`).join(', ')} />
                </Flex.Item>}
            </Flex>),
            onClick: () => {
                handleItemSelected(item)
            },
            endMedia: <Menu
                defaultActiveIndex={0}
                items={[
                    {
                        icon: (
                            <MoreIcon
                                outline
                            />
                        ),
                        key: 'more',
                        indicator: false,
                        menu: {
                            items: [
                                users.includes(auth.user?.uuid!) && {
                                    key: 'unread',
                                    content: t('LABEL.MARK_AS_UNREAD'),
                                    icon: <EyeSlashIcon outline />,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onMarkAsUnread(item)
                                    }
                                },
                                {
                                    key: 'assign',
                                    content: t('LABEL.ASSIGN'),
                                    icon: <ParticipantAddIcon color='primary' outline />,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onAssignModalOpen(item)
                                    }
                                },
                                {
                                    key: 'pinned',
                                    content: pinned ? t('LABEL.UNPIN') : t('LABEL.PIN'),
                                    icon: pinned ? <ThumbtackSlashIcon outline /> : <ThumbtackIcon outline />,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        pinnedConversation(item, pinned ? "TRUE" : "FALSE")
                                    }
                                },
                                {
                                    key: 'edit',
                                    content: t('LABEL.EDIT'),
                                    icon: <EditIcon outline />,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onEditUser(item)
                                    }
                                },
                                {
                                    key: 'archived',
                                    content: item.status === 'ARCHIVED' ? t('LABEL.UNARCHIVE') : t('LABEL.ARCHIVE'),
                                    icon: <ArchiveIcon outline />,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onArchiveConversation(item)
                                    }
                                },
                                users.includes(auth.user?.uuid!) && {
                                    key: 'delete',
                                    content: t('LABEL.DELETE'),
                                    icon: <TrashCanIcon outline />,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onDeleteConversation(item)
                                    }
                                },
                                {
                                    key: 'download',
                                    content: t('LABEL.DOWNLOAD'),
                                    icon: <DownloadIcon outline />,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onDownloadConversation(item)
                                    }
                                }
                            ],
                        }
                    }
                ]}
                iconOnly
                accessibility={menuAsToolbarBehavior}
            />
        };
    }

    const onConfirm = () => {
        if (state.confirm.item) {
            ConversationService.delete(state.confirm.item.uuid).then(resp => {
                if (resp.status === 200) {
                    props.onDeleteConversation(state.confirm.item)
                    setState(prevState => {
                        prevState.confirm.open = false
                        prevState.confirm.item = new ConversationModel()
                        return { ...prevState }
                    })
                }
            })
        }
    }

    const onCancel = () => {
        setState(prevState => {
            prevState.confirm.open = false
            return { ...prevState }
        })
    }

    const pinnedUsers = filter(users, user => user.status !== 'ARCHIVED' && state.pinnedList.includes(user.uuid))
    const recentUsers = filter(users, user => user.status !== 'ARCHIVED' && !state.pinnedList.includes(user.uuid))
    const archivedUsers = filter(users, user => user.status === 'ARCHIVED')

    const pinnedIndex = findIndex(pinnedUsers, user => user.uuid === selected?.uuid)
    const recentIndex = findIndex(recentUsers, user => user.uuid === selected?.uuid)
    const archivedIndex = findIndex(archivedUsers, user => user.uuid === selected?.uuid)

    return (<Box
        styles={({ theme: { siteVariables } }) => ({
            backgroundColor: siteVariables.colorScheme.default.background4,
        })}
    >
        <ConformationDialog
            {...props}
            open={state.confirm.open}
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={state.confirm.title}
            subTitle={state.confirm.subTitle}
        />
        <Flex gap="gap.medium" padding='padding.medium'>
            <Flex fill>
                {state.searchable ? (<Flex.Item size="size.full" >
                    <Input ref={inputRef} styles={{
                        minHeight: '2.5rem',
                    }} iconPosition="end" icon={
                        <CloseIcon onClick={() => onSearchViewChange(false)} />
                    } placeholder={t('LABEL.SEARCH')} fluid onChange={handleChange} />
                </Flex.Item>) : (
                    <Flex.Item size="size.full">
                        <Flex fill space='between' vAlign='center'>
                            <Menu
                                iconOnly
                                styles={{
                                    minHeight: '2.5rem',
                                }}
                                accessibility={menuAsToolbarBehavior}
                                items={[
                                    {
                                        icon: type === 'MY_CONVERSATIONS' ? (<PersonIcon outline />) : (<UserFriendsIcon outline />),
                                        key: 'filter',
                                        indicator: false,
                                        title: 'Filter',
                                        menu: {
                                            items: [
                                                {
                                                    key: 'my',
                                                    content: t('LABEL.MY_CONVERSATIONS'),
                                                    icon: <PersonIcon outline />,
                                                    onClick: () => {
                                                        onTypeSelected('MY_CONVERSATIONS')
                                                    }
                                                },
                                                {
                                                    key: 'all',
                                                    content: t('LABEL.ALL_CONVERSATIONS'),
                                                    icon: <UserFriendsIcon outline />,
                                                    onClick: () => {
                                                        onTypeSelected('INBOX')
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]}
                            />
                            {/* <Text content={t('LABEL.CHAT')} weight='bold' styles={{ paddingLeft: '1rem' }} /> */}
                            <Menu
                                iconOnly
                                styles={{
                                    minHeight: '2.5rem',
                                }}
                                accessibility={menuAsToolbarBehavior}
                                items={[
                                    props.filter === 'ALL' ? {
                                        icon: <FilterIcon outline />,
                                        key: 'filter',
                                        indicator: false,
                                        title: 'Filter',
                                        menu: {
                                            items: [
                                                {
                                                    key: 'active',
                                                    content: t('LABEL.ACTIVE'),
                                                    icon: <EyeIcon outline />,
                                                    onClick: () => {
                                                        props.onFilterClick('ACTIVE')
                                                    }
                                                },
                                                {
                                                    key: 'onbeantwoord',
                                                    content: t('LABEL.UNANSWERED'),
                                                    icon: <ChatIcon outline />,
                                                    onClick: () => {
                                                        props.onFilterClick('UNANSWERED')
                                                    }
                                                },
                                                {
                                                    key: 'ongelezen',
                                                    content: t('LABEL.UNREAD'),
                                                    icon: <EyeIcon outline />,
                                                    onClick: () => {
                                                        props.onFilterClick('UNREAD')
                                                    }
                                                }
                                            ]
                                        }
                                    } : {
                                        content: (<Pill
                                            appearance="outline"
                                            content={<Text content={props.filter === 'ACTIVE' ? t('LABEL.ACTIVE') : props.filter === 'UNANSWERED' ? t('LABEL.UNANSWERED') : t('LABEL.UNREAD')} />}
                                            actionable
                                            size='small'
                                            onDismiss={(e, data) => {
                                                props.onFilterClick('ALL')
                                            }}
                                        />),
                                        iconOnly: true,
                                        key: 'filter',
                                        indicator: false,
                                    },
                                    {
                                        icon: <SearchIcon />,
                                        key: 'zoeken',
                                        title: t('LABEL.SEARCH'),
                                        iconOnly: true,
                                        onClick: () => onSearchViewChange(true),
                                        primary: true,
                                    },
                                    {
                                        icon: <ComposeIcon />,
                                        iconOnly: true,
                                        key: 'new',
                                        title: t('LABEL.NEW'),
                                        onClick: onNewModalOpen,
                                        primary: true,
                                    }
                                ]}
                            />
                        </Flex>
                    </Flex.Item>
                )}
            </Flex>
        </Flex>
        <Divider />
        {state.assign.item && <AssignUser {...props} open={state.assign.open} conversation={state.assign.item} onClose={onAssignModalClose} />}
        <AddContact open={state.new.open} onClose={onNewModalClose} {...props} />
        {state.edit?.item?.client && <EditContact {...props} onSuccess={onEditConversation} open={state.edit.open} item={state.edit.item.client} onClose={onEditModalClose} />}
        <Accordion ref={scrollRef} className='scroll'
            defaultActiveIndex={[0, 1]}
            panels={[
                {
                    title: t('LABEL.PINNED'),
                    content: (
                        <List selectable selectedIndex={pinnedIndex} defaultSelectedIndex={pinnedIndex} items={map(pinnedUsers, renderItem)} key="vastgezet" />
                    ),
                },
                {
                    title: t('LABEL.RECENT'),
                    content: (
                        <List selectable selectedIndex={recentIndex} defaultSelectedIndex={recentIndex} items={map(recentUsers, renderItem)} key="recent" />
                    ),
                },
                {
                    title: t('LABEL.ARCHIVED'),
                    content: (
                        <List selectable selectedIndex={archivedIndex} defaultSelectedIndex={archivedIndex} items={map(archivedUsers, renderItem)} key="archief" />
                    ),
                }
            ]} />
        <Flex className='loader' gap="gap.medium" padding='padding.medium'>
            {loading && <Loader size="smallest" />}
        </Flex>
    </Box >)
}
export default ContactList
